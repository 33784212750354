body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}
:root{
  --main-color:#232020;
  --calendar-color:#F8F6F4;
  --calendar-text-color:#5B6F77;
  --logo-gold:#BC8828;
  --transition-duration-s:0.3s;
  --transition-delay-s:0.1s;
  --card-background-color:#2A2929;
  --card-text-color:#405861;
  --hoover-btn-color:#ACD4E4;
}
*{
  font-family: 'Roboto', sans-serif;
}
@font-face {
  font-family: 'Heading-font';
  src: url(./assets/font/BemboTitlingMTStd.otf);
}
.container{
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.announcement-css{
  height: 40px !important;
}
.announcement-bar-text-css a{
  font-size: 14px;
}
.header-css{
  background-color: var(--main-color);
  box-shadow: 0px 0px 8px var(--card-background-color);
}

.logo-css{
  width: 70% !important;
}
.header-link-css{
  text-transform: uppercase;
  font-size: 14px;
}


/* first section  */
.first-section-css{
  height: 800px;
  background-image: url(./assets//image//background.jpg);
  background-repeat: no-repeat;
}
.firstsection-text-css h1{
  font-family: "Heading-font";
  font-size: 40px;
}
.firstsection-text-css p{
  font-size: 20px;
  font-weight: 400;
}
.firstsection-text-css button{
  color: var(--calendar-text-color);
  text-transform: uppercase;
}
.calendar-css{
  background-color: var(--calendar-color);
  font-family: 'Roboto', sans-serif;
  width: 320px !important;
}
.react-calendar__navigation{
  display: flex;
}
.react-calendar__navigation button{
  background-color: transparent;
  border: none;
  color: #5B6F77;
  text-transform: uppercase;
}
.react-calendar__navigation__prev2-button,.react-calendar__navigation__next2-button{
  display: none;
}
.react-calendar__navigation__prev-button,.react-calendar__navigation__next-button{
  padding-bottom: 6px;
  font-size: 30px;
  color: var(--calendar-text-color);
  font-family: x²;
}
.react-calendar__navigation button span{
  font-family: 'Heading-font';
  font-size: 20px;
}
.react-calendar__month-view__weekdays{
  margin-top: 20px;
  margin-bottom: 10px;
}
.react-calendar__month-view__weekdays div{
  display: flex;
  justify-content: center;
}
.react-calendar__month-view__weekdays div abbr{
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  color: var(--calendar-text-color);
}
.react-calendar__month-view__days button{
  background-color: white;
  border: 1px solid #d1d1d1 ;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
  color: var(--calendar-text-color);
}
.react-calendar__month-view__days button:hover{
  background-color: #d1d1d1;
  transition-delay: var(--transition-delay-s);
  transition-duration: var(--transition-duration-s);
}
/* end first section  */

/* contact pop up style  */
.contact-wrapper-css{
  z-index: 2200;
  position: fixed;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
}
.contact-wrapper-css >div{
  width: 340px;
  background-color: whitesmoke;
  border-radius: 5%;
  padding-bottom: 20px;
}
.contact-image-style{
  width: 200px;
}
.contact-form-css input{
  width: 300px;
  border: 1px solid var(--calendar-text-color);
  outline: none;
  font-size: 14px !important;
}
.contact-form-css input::placeholder{
  font-size: 14px !important;
}
.contact-form-css select{
  width: 300px;
  border: 1px solid var(--calendar-text-color);
  outline: none;
  background-color: white;
  font-size: 14px !important;
}
.contact-form-css select option{
  font-size: 14px !important;
}
.contact-form-css button{
  border: 1px solid black !important;
}
.contact-form-css button:hover{
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
  transition-duration: var(--transition-duration-s);
  transition-delay: var(--transition-delay-s);
}
.close-popup-css{
  cursor: pointer;
}
.background-color-css{
  position: fixed;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  z-index: 50 !important;
  width: 100%;
  height: 1000px;
  opacity: 0.4;
}
/* end contact pop up style  */

/* Second Section wrapper  */
.second-section-icon{
  width: 80px;
}
.coiffure-icon-css,.massage-icon-css,.make-up-icon-css{
  width: 60px !important;
}

.onglerie-icon-css{
  width: 50px !important;
}

.Secondsection-wrapper-css{
  background-color: var(--main-color);
  padding-block: 5rem;
}
.Second-section-text-css h3{
  font-family: 'Heading-font';
  font-size: 18px;
  color: var(--logo-gold) !important;
}
.Second-section-text-css p{
  font-size: 16px;
  max-width: 400px !important;
}
/* end Second section wrapper  */

/* start quotes  */
.Quotes-css{
  background-color: var(--card-background-color);
  padding-top: 216px;
}
.quotes-swiper-css{
  height: 280px;
}
.quote-text-style{
  height: 220px !important;
}
.quote-text-style q{
  min-width: 320px;
  max-width: 700px;
  font-size: 20px;
  font-family: 'Heading-font';
}
.quotes-logo-css{
  width: 300px;
}
.quote-text-style small{
  text-transform: uppercase;
}
.swiper-pagination span{
  background-color: var(--calendar-text-color);
}
/* end quotes */

/* Card styling  */

.Spa-card-wrapper-css{
  background-color: var(--card-background-color);
}
.card-style-css{
  background-image: url(./assets/image/card.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 400px;
  width: 350px !important;
}
.card-image1{
  background-image: url(./assets/image/card2.jpg) !important;
}
.card-image2{
  background-image: url(./assets/image/card3.jpg) !important;
}
.card-style-css article{
  background-color: white;
}
.card-style-css article h4{
  font-family: 'Heading-font';
  font-size: 17px;
  color: var(--card-text-color);
}
.card-style-css article p{
  opacity: 0.8;
  line-height: 1.8em;
}
.card-style-css article button{
  text-transform: uppercase;
  font-size: 14px;
}
.card-style-css article button abbr{
  position: relative;
  z-index: 4;
}
.card-style-css article button span{
  position: absolute;
  transform: translate(-50%,-50%);
  bottom: 0;
  left: 60%;
  width: 100px;
  height: 8px;
  background-color: #E5ECEF;
}
/* end card styling  */

/* pricing wrapper styling  */
.Pricing-wrapper-css{
  background-color: var(--main-color);
}
.pricing-card-line{
  background-color: #393737;
  height: 1px;
  width: 100%;
}
.pricing-card-css article h5{
  color: var(--logo-gold);
  font-family: 'Heading-font';
  font-size: 18px;
  white-space: nowrap;
}
.card-price-css{
  max-width: 40px !important;
}
.pricing-card-css p{
  color: var(--calendar-color);
  opacity: 0.8;
  font-size: 16px;
}

/* end piricing styling  */

/* Pack styling  */
.pack-wrapper-css{
  background-color: var(--main-color);
}
.pack-text-style-css h2{
  font-family: 'Heading-font';
  font-size: 30px;
}
.pack-text-style-css p{
  font-size: 18px;
  max-width: 800px;
}
.Pack-cards-css{
  padding-block: 70px;
  border: 1px solid var(--calendar-text-color);
  width: 350px !important;
}
.Pack-cards-css h6{
  font-family: 'Heading-font';
  font-size: 25px;
}
.Pack-cards-css p{
  width: 240px;
  line-height: 2;
}
.pack-card-style-price-css span{
  font-family: 'Heading-font';
  color: var(--logo-gold);
  font-size: 70px;
}
.pack-button-css{
  text-transform: uppercase;
  font-size: 14px;
}
.popular-pack-css{
  background-color: #3B3B3B;
  border: 1px solid #3B3B3B;
}
.popular-pack-css small{
  color: var(--calendar-text-color);
  text-transform: uppercase;
  margin-bottom: 10px;
}
.luxury-pack-css{
  background-color: #2D2C2C;
  border: 1px solid #2D2C2C;
}
/* end pack styling  */

/* special offre  */
.specialoffre-wrapper-css{
  background-color: var(--main-color);
  
}
.specialoffre-text-css{
  background-image: url(./assets/image/special\ offre.jpg);
  background-repeat: no-repeat;
  padding-left: 50px;
  padding-right: 50px;
}
.specialoffre-text-css small{
  text-transform: uppercase;
}
.specialoffre-text-css h2{
  font-family: 'Heading-font';
  font-size: 40px;
}
.specialoffre-text-css p{
  max-width: 600px;
  font-size: 18px;
}
.specialoffre-text-css button{
  text-transform: uppercase;
  font-size: 14px;
  color: var(--calendar-text-color);
}
/* end special offre  */

/* working hours style  */

.working-hours-wrapper{
  background-color: var(--main-color);
}
.working-hours-background-image-css{
  background-color: #2D2C2C;
  width: 70%;
  height: 300px;
  position: absolute;
}
.working-hours-image-css{
  position: relative;
  z-index: 10;
}
.working-hour-image-wrapper{
  margin-bottom:100px ;
}
.Working-hours-text-css h2{
  font-family: 'Heading-font';
}
.blog-wrapper-css{
  background-color: var(--main-color);
}

/* end working hours style  */

/* blog style  */
.blog-created-css{
  font-size: 13px;
}
.blogs-text-css h6{
  font-family: 'Heading-font';
  font-size: 14px;
}
.blogs-text-css button{
  text-transform: uppercase;
  font-size: 14px;
}
.blogs-text-css button abbr{
  position: relative;
  z-index: 4;
}
.blogs-text-css button span{
  position: absolute;
  transform: translate(-50%,-50%);
  bottom: 0;
  left: 60%;
  width: 100px;
  height: 8px;
  background-color: #41464A;
}
/* end blog style  */

/* news lettre form  */
.newsletter-wrapper-css{
  background-color: var(--main-color);
}
.news-lettre-css h2{
  font-family: 'Heading-font';
  font-size: 20px;
}
.news-lettre-css form input{
  width: 100%;
  background-color: #424242;
  outline: none;
}
.news-lettre-css form input::placeholder{
  color: #818181;
  text-transform: capitalize;
  font-size: 14px;
}

.news-lettre-css form button{
  background-color: #92C5DA;
  width: 180px;
}
/* end news lettre  */


/* footer styling */
.footer-wrapper-css{
  background-color: var(--main-color);
}
.footer-col-css h6{
  font-family: 'Heading-font';
  font-size: 18px;
}
.footer-col-css button{
  text-transform: uppercase;
  font-size: 14px;
}
.footer-col-css button abbr{
  position: relative;
  z-index: 4;
}
.footer-col-css button span{
  position: absolute;
  transform: translate(-50%,-50%);
  bottom: 0;
  left: 56%;
  width: 110px;
  height: 9px;
  background-color: #41464A;
}
.footer-col-css a{
  font-size: 15px;
}
.footer-col-css a:hover{
  color: white !important;
  font-size: 15px;
  transition-duration: var(--transition-duration-s);
  transition-delay: var(--transition-delay-s);
}
.footer-col-css div span{
  font-size: 15px;
}
/* end footer styling  */

.video-wrapper{
  background-color: var(--main-color);
  height: 270px;
}

/* hoovers */
.btnhoover:hover{
  background-color: var(--logo-gold) !important;
  color: white !important;
  transition-duration: var(--transition-duration-s);
  transition-delay: var(--transition-delay-s);
}
/* end hoovers */

@media (min-width: 768px){
  h1{
    font-size: 50px !important;
  }
  .calendar-css{
    width: unset !important;
  }
  .contact-wrapper-css >div{
    width: 400px;
  }
  .Second-section-text-css p{
    max-width: 100% !important;
  }
  .quote-text-style{
    height: 160px !important;
  }
  .quotes-swiper-css{
    height: 240px;
  }
  .card-style-css{
    width: 100% !important;
  }
  .pack-text-style-css h2{
    font-family: 'Heading-font';
    font-size: 40px;
  }
  
  .working-hours-background-image-css{
    height: 480px;
  }
  .news-lettre-css form input{
    width: 350px;
  }
  .second-section-icon{
    width: 130px;
  }
  .coiffure-icon-css,.massage-icon-css,.make-up-icon-css{
    width: 80px !important;
  }
  .onglerie-icon-css{
    width: 35px !important;
  }
}

@media (min-width: 992px) {
  h1{
    font-size: 65px !important;
  }
  .quote-text-style{
    height: 120px !important;
  }
  .quotes-swiper-css{
    height: 200px;
  }
  .card-style-css{
    width: 350px !important;
  }
  .specialoffre-text-css{
    background-size: 100%;
  }
  .working-hours-background-image-css{
    height: 480px;
  }
  .working-hour-image-wrapper{
    margin-bottom:unset ;
  }
  .second-section-icon{
    width: 180px !important;
  }
  .coiffure-icon-css,.massage-icon-css,.make-up-icon-css{
    width: 100px !important;
  }
  .onglerie-icon-css{
    width: 35px !important;
  }
}

.lazy-loading-css{
  background-color: var(--main-color);
}
.lazy-loading-css div img{
  max-width: 200px;
}
.error-page-css{
  background-color: var(--main-color);
}
.error-page-css a{
  background-color: var(--logo-gold);
}

/* Blogs css  */
.blog-wrapper-css{
  background-color: var(--main-color);
}
.blog-heading{
  font-family: 'Heading-font';
  color: var(--logo-gold);
}
.main-blog-css img{
  width: 100%;
}
.main-blog-css article div{
  font-size: 12px;
}
.main-blog-css article h2{
  color: var(--logo-gold);
  font-family: 'Heading-font';
}
.main-blog-css article h3{
  color: var(--logo-gold);
  font-family: 'Heading-font';
  font-size: 18px;
}
.main-blog-css article p{
  font-size: 14px;
}
@media (min-width: 992px){
  .main-blog-css img{
    max-width: 70%;
  }
  .main-blog-css article p{
    width: 60%;
  }
}
/* end blog css  */